import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ "icon", "form", "q" ]

    showForm() {
        // this.formTarget.classList.remove("nav-link")
        // this.iconTarget.classList.add("hidden")
        this.iconTarget.remove()

        this.formTarget.classList.remove("hidden")
        this.formTarget.classList.add("display-inline")
        this.qTarget.focus()
    }

}
