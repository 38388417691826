export default class Typeface {
    constructor(context) {
        this.context = context
        this.pixelSize = 8 // measured in screen pixels
        this.gridSize = 5 // measured in grid pixels
        this.charSpacing = 1 // measured in grid pixels
        this.jitter = 0
    }

    render(x, y, text) {
        var cursorX = x
        text.split("").forEach((character, index) => {

            this.context.save()
            this.context.translate(cursorX, y)

            this.drawChar(character)

            this.context.restore()

            cursorX = cursorX + (this.gridSize * this.pixelSize) + (this.charSpacing * this.pixelSize)

        })

    }

    drawChar(character) {
        switch (character.toUpperCase()) {
            case "A":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 3)

                this.drawPixel(2, 0)
                this.drawPixel(2, 3)

                this.drawPixel(3, 0)
                this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "B":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 1)
                this.drawPixel(4, 3)
                break

            case "C":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 4)
                break

            case "D":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 4)

                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                break

            case "E":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 4)
                break

            case "F":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)

                this.drawPixel(4, 0)
                break

            case "G":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                // this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "H":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 2)

                this.drawPixel(2, 2)

                this.drawPixel(3, 2)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "I":
                this.drawPixel(0, 0)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 4)
                break

            case "J":
                this.drawPixel(0, 0)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)

                this.drawPixel(4, 0)
                break

            case "K":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 2)

                this.drawPixel(2, 2)
                // this.drawPixel(2, 3)

                // this.drawPixel(3, 1)
                this.drawPixel(3, 2)
                // this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "L":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 4)

                this.drawPixel(2, 4)

                this.drawPixel(3, 4)

                this.drawPixel(4, 4)
                break

            case "M":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)

                this.drawPixel(2, 1)

                this.drawPixel(3, 0)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "N":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 1)

                this.drawPixel(2, 2)

                this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "O":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "P":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 3)

                this.drawPixel(2, 0)
                this.drawPixel(2, 3)

                this.drawPixel(3, 0)
                this.drawPixel(3, 3)

                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                break

            case "Q":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 4)
                break

            case "R":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 3)

                this.drawPixel(2, 0)
                this.drawPixel(2, 3)

                this.drawPixel(3, 0)
                this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 4)
                break

            case "S":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "T":
                this.drawPixel(0, 0)

                this.drawPixel(1, 0)

                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)

                this.drawPixel(4, 0)
                break

            case "U":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 4)

                this.drawPixel(2, 4)

                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "V":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)

                this.drawPixel(1, 3)

                this.drawPixel(2, 4)

                this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                break

            case "W":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 4)

                this.drawPixel(2, 3)

                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "X":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 2)

                this.drawPixel(2, 2)

                this.drawPixel(3, 2)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "Y":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)

                this.drawPixel(1, 2)

                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 2)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                break

            case "Z":
                this.drawPixel(0, 0)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 3)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 1)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 4)
                break

            case "1":
                this.drawPixel(0, 0)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 4)

                this.drawPixel(4, 4)
                break

            case "2":
                this.drawPixel(0, 0)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 4)
                break

            case "3":
                this.drawPixel(0, 0)
                this.drawPixel(0, 2)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "4":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)

                this.drawPixel(1, 3)

                this.drawPixel(2, 3)

                this.drawPixel(3, 3)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "5":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "6":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "7":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)

                this.drawPixel(1, 0)

                this.drawPixel(2, 0)

                this.drawPixel(3, 0)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break


            case "8":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "9":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "0":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "!":
                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)

                this.drawPixel(2, 4)
                break

            case "@":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "#":
                this.drawPixel(0, 1)
                this.drawPixel(0, 3)

                this.drawPixel(1, 0)
                this.drawPixel(1, 1)
                this.drawPixel(1, 2)
                this.drawPixel(1, 3)
                this.drawPixel(1, 4)

                this.drawPixel(2, 1)
                this.drawPixel(2, 3)

                this.drawPixel(3, 0)
                this.drawPixel(3, 1)
                this.drawPixel(3, 2)
                this.drawPixel(3, 3)
                this.drawPixel(3, 4)

                this.drawPixel(4, 1)
                this.drawPixel(4, 3)
                break

            case "£":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                // this.drawPixel(3, 1)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 4)
                break

            case "$":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 2)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 0)
                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "%":
                this.drawPixel(0, 0)
                this.drawPixel(0, 4)

                this.drawPixel(1, 3)

                this.drawPixel(2, 2)

                this.drawPixel(3, 1)

                this.drawPixel(4, 0)
                this.drawPixel(4, 4)
                break

            case "^":
                this.drawPixel(1, 1)

                this.drawPixel(2, 0)

                this.drawPixel(3, 1)
                break

            case "&":
                this.drawPixel(0, 0)
                this.drawPixel(0, 1)
                this.drawPixel(0, 3)
                this.drawPixel(0, 4)

                this.drawPixel(1, 0)
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                this.drawPixel(4, 2)
                this.drawPixel(4, 3)
                this.drawPixel(4, 4)
                break

            case "*":
                this.drawPixel(1, 1)
                this.drawPixel(1, 3)

                this.drawPixel(2, 2)

                this.drawPixel(3, 1)
                this.drawPixel(3, 3)

                break

            case "(":
                this.drawPixel(1, 1)
                this.drawPixel(1, 2)
                this.drawPixel(1, 3)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                break

            case ")":
                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 1)
                this.drawPixel(3, 2)
                this.drawPixel(3, 3)

                break

            case "-":
                this.drawPixel(1, 2)
                this.drawPixel(2, 2)
                this.drawPixel(3, 2)
                break

            case "_":
                this.drawPixel(0, 4)
                this.drawPixel(1, 4)
                this.drawPixel(2, 4)
                this.drawPixel(3, 4)
                this.drawPixel(4, 4)

                break

            case "=":
                this.drawPixel(1, 1)
                this.drawPixel(2, 1)
                this.drawPixel(3, 1)

                this.drawPixel(1, 3)
                this.drawPixel(2, 3)
                this.drawPixel(3, 3)
                break

            case "+":
                this.drawPixel(1, 2)

                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)

                this.drawPixel(3, 2)
                break

            case "[":
                this.drawPixel(1, 0)
                this.drawPixel(1, 1)
                this.drawPixel(1, 2)
                this.drawPixel(1, 3)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                break

            case "]":
                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 1)
                this.drawPixel(3, 2)
                this.drawPixel(3, 3)
                this.drawPixel(3, 4)

                break

            case "{":
                this.drawPixel(0, 2)

                this.drawPixel(1, 0)
                this.drawPixel(1, 1)
                this.drawPixel(1, 3)
                this.drawPixel(1, 4)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                break

            case "}":
                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 1)

                this.drawPixel(3, 3)
                this.drawPixel(3, 4)

                this.drawPixel(4, 2)

                break

            case ";":
                this.drawPixel(2, 1)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)
                break

            case ":":
                this.drawPixel(2, 1)
                this.drawPixel(2, 3)

                break

            case "'":
                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                break

            case '"':
                this.drawPixel(1, 0)
                this.drawPixel(1, 1)

                this.drawPixel(3, 0)
                this.drawPixel(3, 1)
                break

            case ",":
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                break

            case ".":
                this.drawPixel(2, 4)

                break

            case "/":
                this.drawPixel(0, 4)

                this.drawPixel(1, 3)

                this.drawPixel(2, 2)

                this.drawPixel(3, 1)

                this.drawPixel(4, 0)

                break

            case "\\":
                this.drawPixel(0, 0)

                this.drawPixel(1, 1)

                this.drawPixel(2, 2)

                this.drawPixel(3, 3)

                this.drawPixel(4, 4)

                break

            case "?":
                this.drawPixel(0, 0)

                this.drawPixel(1, 0)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 0)
                this.drawPixel(3, 2)

                this.drawPixel(4, 0)
                this.drawPixel(4, 1)
                this.drawPixel(4, 2)
                break

            case "<":
                this.drawPixel(0, 2)

                this.drawPixel(1, 1)

                this.drawPixel(1, 3)

                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                break

            case ">":
                this.drawPixel(2, 0)
                this.drawPixel(2, 4)

                this.drawPixel(3, 1)

                this.drawPixel(3, 3)

                this.drawPixel(4, 2)

                break

            case "|":
                this.drawPixel(2, 0)
                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                break


            case "~":

            this.drawPixel(0, 2)

                this.drawPixel(1, 1)
            
                this.drawPixel(2, 2)

                this.drawPixel(3, 3)

                this.drawPixel(4, 2)

                break

            case "±":
                this.drawPixel(1, 2)
                this.drawPixel(1, 4)

                this.drawPixel(2, 1)
                this.drawPixel(2, 2)
                this.drawPixel(2, 3)
                this.drawPixel(2, 4)

                this.drawPixel(3, 2)
                this.drawPixel(3, 4)

                break

            case "§":
                this.drawPixel(1, 1)
                this.drawPixel(1, 3)

                this.drawPixel(2, 0)
                this.drawPixel(2, 2)
                this.drawPixel(2, 4)

                this.drawPixel(3, 1)
                this.drawPixel(3, 3)

            default:
                break
        }
    }

    drawPixel(x, y) {
        // var jitter = 0
        if (Math.random() < this.jitter) {
            x = x + -1
            y = y + -1
        } else if (Math.random() < this.jitter) {
            x = x + 1
            y = y + 1
        }
        this.context.fillStyle = "white"
        this.context.fillRect(x * this.pixelSize, y * this.pixelSize, this.pixelSize, this.pixelSize)
    }
}