import { Controller } from "@hotwired/stimulus"
// import { randomInt } from "./helpers"

class WolfRayCasterController extends Controller {

    // Stimulus targets
    static targets = ["canvas", "framerate"]

    // Canvas variables
    // width = 480
    // height = 320
    width = 320
    height = 240
    // width = 18
    // height = 12
    pixelCount = this.width * this.height
    pixelArrayLength = this.pixelCount * 4

    // Framerate variables
    startTimestamp = document.timeline.currentTime
    previousTimestamp = 0
    differenceTime = 0

    targetFramerate = 30
    targetFramerateTime = 1000 / this.targetFramerate

    frameratesWindow = 60
    framerates = new Array(this.frameratesWindow + 1).fill(this.targetFramerate)

    degreesToRadians = Math.PI / 180

    fov = 90
    hfov = this.fov / 2
    
    // Animation variables
    nextAnimationFrame = undefined

    keyboardState = {
        w: false,
        s: false,
        a: false,
        d: false,
        Shift: false
    }

    // Canvas Elements

    player = {
        x: 1.50,
        y: 1.50,
        speed: 1,
        angle: 0 // in degrees
    }

    map = [
        [1,1,1,1,1,1,1,1,1,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,1,1,1,1,0,0,0,0,1],
        [1,0,0,0,1,0,0,0,0,1],
        [1,0,0,0,1,0,0,0,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,0,0,0,0,0,0,1,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,0,0,0,1,0,0,0,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,0,0,0,0,0,0,1,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,0,0,1,1,0,0,0,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,0,0,0,0,0,0,1,0,1],
        [1,0,0,0,0,0,0,0,0,1],
        [1,1,1,1,1,1,1,1,1,1]
    ]

    connect() {
        console.log("Hello from canvasbuffers_controller.js")
    
        this.framerateElement = this.framerateTarget

        this.canvas = this.canvasTarget
        this.context = this.canvas.getContext("2d")
    
        this.canvas.width = this.width
        this.canvas.height = this.height

        this.canvas.style.width = "50%"
        this.canvas.style.imageRendering = "pixelated"

        document.addEventListener('keydown', this.keyboardHandler.bind(this))
        document.addEventListener('keyup', this.keyboardHandler.bind(this))

        window.requestAnimationFrame(this.drawFrame.bind(this))
    }

    keyboardHandler(event) {
        // console.log(event.key)
        this.keyboardState[event.key] = event.type == 'keydown';
    }

    drawFrame(timestamp) {
        // Request the next frame
        this.nextAnimationFrame = window.requestAnimationFrame(this.drawFrame.bind(this))
        this.differenceTime = (timestamp - this.previousTimestamp)

        // only draw if we have passed enough time to meet our target framerate
        if (this.differenceTime < this.targetFramerateTime) {
            // return because we are too fast
            return
        }

        this.framerates = this.framerates.slice(1) // Copy the array, removing the first element
        this.framerates.push(parseInt(1000 / (timestamp - this.previousTimestamp))) // Add the current frameTime
        this.framerateElement.innerText = parseInt(this.framerates.reduce((a, b) => a + b, 0) / this.frameratesWindow) // Update the framerate element
        this.previousTimestamp = timestamp // Update the previous timestamp for framerate calculation

        // Create a new image data buffer
        let imageData = this.context.createImageData(this.width, this.height)

        this.drawBackground(imageData)
        
        // Update player position
        if (this.keyboardState.Shift) {
            this.player.speed = 1
        } else {
            this.player.speed = 0.2
        }
        if (this.keyboardState.w) {
            // TODO: set velocity, then move player in the update loop
            this.player.x = this.player.x + Math.cos(this.player.angle * this.degreesToRadians) * this.player.speed
            this.player.y = this.player.y + Math.sin(this.player.angle * this.degreesToRadians) * this.player.speed
        }
        if (this.keyboardState.s) {
            // TODO: set velocity, then move player in the update loop
            this.player.x = this.player.x - Math.cos(this.player.angle * this.degreesToRadians) * this.player.speed
            this.player.y = this.player.y - Math.sin(this.player.angle * this.degreesToRadians) * this.player.speed
        }
        if (this.keyboardState.a) {
            this.player.angle -= 15
        }
        if (this.keyboardState.d) {
            this.player.angle += 15
        }

        let vPixel = 0
        // vPixel = 0
        // Do something with ray casting
        for (let rayAngle = this.player.angle - this.hfov; rayAngle < this.player.angle + this.hfov; rayAngle += (this.fov / this.width)) {
            
            let ray = this.castRay(this.player.x, this.player.y, rayAngle, imageData)

            //console.log(ray)

            // this.drawRect(vPixel, ((this.height / 2) - (this.height / 2 / ray.distance)), 1, (this.height / ray.distance), randomInt(150, 180), randomInt(150, 180), randomInt(150, 180), 255, imageData)

            let wallHeight = (this.height / ray.distance)
            wallHeight = wallHeight > this.height ? this.height : wallHeight
            wallHeight = Math.floor(wallHeight)

            let wallTop = ((this.height / 2) - (wallHeight / 2))
            wallTop = wallTop < 0 ? 0 : wallTop
            wallTop = Math.floor(wallTop)

            let wallColour = 200 - (ray.distance * 15)

            //console.log(vPixel)
            //console.log(wallTop)
            //console.log(wallHeight)
            this.drawRect(vPixel, wallTop, 1, wallHeight, wallColour, wallColour, wallColour, 255, imageData)

            vPixel++

        }

        this.drawMiniMap(imageData)
        this.drawMiniMapPlayer(imageData)
        
        //this.invertColours(imageData)

        this.context.putImageData(imageData, 0, 0)
    }

    // returns the array index of the pixel at x, y
    translateXYToIndex(x, y) {
        return Math.floor(((this.width * 4) * y) + (x * 4))
    }

    castRay(x, y, angle, imageData) {

        // let rayVertical = this.detectVerticalCollision(x, y, angle)
        // console.log(rayVertical)

        // // this.drawRect(rayVertical.gX, rayVertical.gY, 1, 1, 210, 50, 50, 255, imageData)

        // let rayHorizontal = this.detectHorizontalCollision(x, y, angle)
        // console.log(rayHorizontal)

        // if (rayVertical.rayDist < rayHorizontal.rayDist) {
        //     // return({
        //     //     angle: angle,
        //     //     distance: rayVertical.vH,
        //     //     verticalHit: true,
        //     // })
        //     this.drawRect(rayVertical.gridX, rayVertical.gridY, 1, 1, 50, 200, 50, 255, imageData)
        // } else {
        //     // return({
        //     //     angle: angle,
        //     //     distance: rayHorizontal.vH,
        //     //     verticalHit: false,
        //     // })
        //     this.drawRect(rayHorizontal.gridX, rayHorizontal.gridY, 1, 1, 250, 50, 50, 255, imageData)
        // }

        let rayX = x
        let rayY = y

        let distance = 0.0
        let rayHit = false

        let rayAngleRadians = angle * this.degreesToRadians
        let xCos = Math.cos(rayAngleRadians)
        let ySin = Math.sin(rayAngleRadians)

        while (!rayHit) {
            rayX += xCos * 0.01
            rayY += ySin * 0.01
            distance += 0.01

            // this.drawRect(
            //     Math.floor(rayX), 
            //     Math.floor(rayY), 
            //     1, 
            //     1, 
            //     210,
            //     50,
            //     50,
            //     255,
            //     imageData)

            if (this.map[Math.floor(rayY)][Math.floor(rayX)] != 0) {
                rayHit = true
            }
        }

        console.log(distance)

        return({
            angle: angle,
            distance: distance,
            verticalHit: false,
            // hitX
            // hitY
            // WallID
        })
    }

    detectVerticalCollision(x, y, angle) {

        // TODO: calculate if the ray is facing left or right

        let hit = false
        // let i = 0

        let rayX = x // The current X position of the ray
        let rayY = y // The current Y position of the ray
        let rayDist = 0.0 // The distance fom origin(x,y) to vX, vY

        let gridX = Math.floor(x) // The grid X position. Initially the same as the ray X position, but then will be the cell the ray is entering
        let gridY = Math.floor(y) // The grid Y position. Initially the same as the ray Y position, but then will be the cell the ray is entering

        while(!hit) {
            rayX = gridX + 1 // set the new X position to the next grid cell
            rayY = y + (Math.tan(angle * this.degreesToRadians) * (x - gridX)) // calculate the new Y position based on the angle of the ray
            rayDist = Math.cos(angle * this.degreesToRadians) * (x - gridX) // calculate the distance from the origin to the new X,Y position

            gridX = (rayX > 0) ? Math.floor(rayX) : 0 // store the new grid X position
            gridY = (rayY > 0) ? Math.floor(rayY) : 0 // store the new grid Y position

            // check for a wall collision
            if ( this.map[gridX][gridY] != 0 ) {
                console.log("hit")
                hit = true
            }
        }

        return({
            rayX: rayX,
            rayY: rayY,
            rayDist: rayDist,
            gridX: gridX,
            gridY: gridY
            // wallID: this.map[gX][gY]
        })
    }

    // SOHCAHTOA
    // sin = Opp / Hyp
    // cos = Adj / Hyp
    // tan = Opp / Adj

    detectHorizontalCollision(x, y, angle) {
        let hit = false
        // let i = 0

        let vX = x // The current X position of the ray
        let vY = y // The current Y position of the ray
        let vD = 0.0 // The distance fom origin to x,y

        let gX = Math.floor(x) // The grid X position. Initially the same as the ray X position, but then will be the cell the ray is entering
        let gY = Math.floor(y) // The grid Y position. Initially the same as the ray Y position, but then will be the cell the ray is entering

        while(!hit) {
            // vX = gX + 1 
            // vY = y + (Math.tan(angle * this.degreesToRadians) * (x - gX))
            // vD = Math.cos(angle * this.degreesToRadians) * (x - gX)

            // gX = (vX > 0) ? Math.floor(vX) : 0
            // gY = (vY > 0) ? Math.floor(vY) : 0
            
            vX = x + (Math.cos(angle * this.degreesToRadians) * (y - gY))
            vY = gY + 1
            vD = Math.sin(angle * this.degreesToRadians) * (y - gY)

            gX = (vX > 0) ? Math.floor(vX) : 0
            gY = (vY > 0) ? Math.floor(vY) : 0

            // check for a wall collision
            if ( this.map[gX][gY] != 0 ) {
                console.log("hit")
                hit = true
            }
        }

        return({
            vX: vX,
            vY: vY,
            vD: vD,
            gX: gX,
            gY: gY
            // wallID: this.map[gX][gY]
        })
    }

    drawBackground(imageData) {
        imageData.data.fill(0)

        // Fill black
        // for (let pixelIndex = 0; pixelIndex < this.pixelArrayLength; pixelIndex+=4) {
        //     imageData.data[pixelIndex + 3] = 255 // Alpha
        // }
        this.drawRect(0, 0, this.width, this.height / 2, 50, 50, 50, 255, imageData)
        this.drawRect(0, this.height / 2, this.width, this.height / 2, 20, 20, 20, 255, imageData)
    }

    drawMiniMap(imageData) {
        for (let y = 0; y < this.map.length; y++) {
            for (let x = 0; x < this.map[y].length; x++) {
                if (this.map[y][x] == 1) {
                    this.drawRect(
                        (x), 
                        (y), 
                        1,
                        1,
                        200,
                        200,
                        200,
                        255,
                        imageData)                    
                }
            }
        }
    }

    drawMiniMapPlayer(imageData) {
        this.drawRect(Math.floor(this.player.x), Math.floor(this.player.y), 1, 1, 255, 255, 255, 255, imageData)

        this.drawRect(
            Math.floor(this.player.x + Math.cos(this.player.angle * this.degreesToRadians) * 3), 
            Math.floor(this.player.y + Math.sin(this.player.angle * this.degreesToRadians) * 3), 
            1, 
            1, 
            200,
            200,
            200,
            255,
            imageData)
    }

    drawRect(x, y, width, height, r, g, b, a, imageData) {
        for (let w = 0; w < width; w++) {
            for (let h = 0; h < height; h++) {
                // find the position of x,y in the imageData array
                let pos = this.translateXYToIndex(x+w, y+h)
                imageData.data[pos] = r
                imageData.data[pos+1] = g
                imageData.data[pos+2] = b
                imageData.data[pos+3] = a
            }
        }
    }

    invertColours(imageData) {
        for (let i = 0; i < this.pixelArrayLength; i+=4) {
            imageData.data[i] = 255 - imageData.data[i]
            imageData.data[i+1] = 255 - imageData.data[i+1]
            imageData.data[i+2] = 255 - imageData.data[i+2]
        }
    }

    // TODO: drawLine(x1, y1, x2, y2, imageData) {
}

export default WolfRayCasterController