import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

export default class extends Controller {

    static targets = ["svg"]

    connect() {
        var width = this.element.offsetWidth
        var height = width

        var data = JSON.parse(this.svgTarget.dataset.data)
        console.log("data", data)

        var links = JSON.parse(this.svgTarget.dataset.links)
        console.log("links", links)

        var svg = d3.select("svg#sitemap")
            // .attr("viewBox", [0, 0, width, height])
            .attr("width", width)
            .attr("height", height)

        var simulation = d3.forceSimulation(data)
            // .force("center", d3.forceCenter(width/2, height/2).strength(0.05))
            .force("charge", d3.forceManyBody().strength(-50))
            .force("collide", d3.forceCollide().radius(10))
            .force("link", d3.forceLink(links).id(d => d.id).strength(1))
            .force("x", d3.forceX(width / 2))
            .force("y", d3.forceY(height / 2))

        const link = svg.append("g")
            .attr("class", "links")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(links)
            .enter()
            .append("line")
            .attr("stroke-width", 2)

        const node = svg.append("g")
            .selectAll(".node")
            .data(data)
            .join("g")
            .attr('class', function (d) { return 'node ' + d.klass })

        node.append('circle')
            .attr("r", 5)
            .attr("fill", function (d) { return d.colour })
            .on("click", function (event, d) { window.location.href = d.url })
            .call(drag(simulation));

        node.append("text")
            .attr('class', function(d) { return `label ${d.klass}` })
            .text(function (d) { return d.title })
            // .style('fill', '#fff')
            // .style('font-size', '10px')
            .attr('x', 10)
            .attr('y', 4)



        simulation.on("tick", tickActions)

        function tickActions() {
            node
                .attr("transform", d => `translate(${d.x}, ${d.y})`);

            link
                .attr("x1", function (d) { return d.source.x; })
                .attr("y1", function (d) { return d.source.y; })
                .attr("x2", function (d) { return d.target.x; })
                .attr("y2", function (d) { return d.target.y; });

        }

        function drag(simulation) {

            function dragstarted(event, d) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            }

            function dragged(event, d) {
                d.fx = event.x;
                d.fy = event.y;
            }

            function dragended(event, d) {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }

            return d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended);
        }

    }

}
