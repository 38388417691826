import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    // static targets = ["extension"]
    static targets = ["tab", "panel"]

    connect() {
        this.showTab(0)
    }

    change(e) {
        this.showTab(this.tabTargets.indexOf(e.target))
    }

    showTab(index) {
      this.tabTargets.forEach((element, i) => {
        if (i == index) {
          element.classList.add('font-bold')
        } else {
          element.classList.remove('font-bold')
        }
      })

      this.panelTargets.forEach((element, i) => {
        if (i == index) {
          element.classList.remove('hidden')
        } else {
          element.classList.add('hidden')
        }
      })
    }

}
