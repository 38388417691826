import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";
import "moment";

export default class extends Controller {

    static targets = ["canvas"]

    connect() {
        this.renderChart();
    }

    renderChart() {
        const ctx = this.canvasTarget.getContext("2d");

        new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [{
                    label: 'photos',
                    data: JSON.parse(this.canvasTarget.dataset["dataset-data"]),
                }]
            },
            options: {
                responsive: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'year'
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            }
        });

    }
}
