import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["extension"]

    expand(event) {
        event.preventDefault();
        event.target.classList.add("hidden")
        this.extensionTarget.classList.remove("hidden")
    }

    // collapse(event) {
    //     event.preventDefault();
    //     this.extensionTarget.classList.add("hidden")
    // }
}
