export const randomInt = (min, max) => {
    return parseInt(randomFloat(min, max))
}

export const randomFloat = (min, max) => {
    return Math.random() * (max-min) + min
}

export const randomElement = (array) => {
    return array[randomInt(0, array.length)]
}