import { Controller } from "@hotwired/stimulus"
import Typeface from "./typeface"


export default class extends Controller {

    static targets = ["canvas", "jitter", "text"]

    connect() {
        console.log("pixel type controller connected")

        this.canvas = this.canvasTarget

        this.CanvasContext = this.canvas.getContext("2d")

        this.canvas.width = window.innerWidth
        this.canvas.height = window.innerHeight

        console.log(this)

        this.draw()
    }

    disconnect() {
        console.log("pixel type controller disconnected")
    }

    canvasTargetConnected(e) {
        console.log("canvas target connected")
    }
    canvasTargetDisconnected(e) {
        console.log("canvas target disconnected")
    }

    changeHandler(event) {
        this.draw()
    }

    draw() {
        this.CanvasContext.fillStyle = "black"
        this.CanvasContext.fillRect(0, 0, this.canvas.width, this.canvas.height)

        var typeface = new Typeface(this.CanvasContext)
        typeface.jitter = this.jitterTarget.value

        this.textTarget.value.split("\n").forEach((line, index) => {
            typeface.render(400, 50 + (index * 50), line)
        })
    }
}
