import { Controller } from "@hotwired/stimulus"
import { randomInt } from "./helpers"

export default class extends Controller {

    static targets = [ "canvas" ]

    connect() {
        this.canvas = this.canvasTarget
        this.context = this.canvas.getContext("2d")

        this.canvas.width  = window.innerWidth
        this.canvas.height = window.innerHeight

        this.draw()
    }

    draw() {
        this.context.fillStyle = "white"
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)

        this.context.fillStyle = "white"

        this.context.lineWidth = 2
        this.context.strokeStyle = "black"

        // this.context.font = "48px Marker Felt wide";
        // this.context.strokeText("Hello world", 10, 50);
        for(var i = 0; i < 500; i++) {
            this.context.rotate((randomInt(0, 60) - 30) * Math.PI / 180)
            this.drawText("FUCK", randomInt(0, this.canvas.width), randomInt(0, this.canvas.height), randomInt(50, 100))
            this.context.setTransform(1, 0, 0, 1, 0, 0);
        }

        this.context.lineWidth = 4
        this.drawText("FUCK IT", this.canvas.width / 3, this.canvas.height / 2, "300")
    }

    drawText(text, x, y, size) {
        this.context.font = `${size}px Marker Felt wide`
        // this.context.fillStyle = "black"
        this.context.fillText(text, x, y)
        this.context.strokeText(text, x, y)
    }
}
