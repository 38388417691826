import { Controller } from "@hotwired/stimulus"
import { randomFloat, randomInt } from "./helpers"

class Cell {
    constructor(context, x, y, size, backgroundColour, boarderColour) {
        this.context = context
        this.x = x
        this.y = y
        this.size = size

        this.backgroundColour = backgroundColour
        this.boarderColour = boarderColour

        this.angle = 2 * Math.PI / 6;

        this.margin = 2
        this.padding = 4

        this.label = ""
    }

    draw() {
        

        // Background
        this.context.fillStyle = this.backgroundColour
        this.context.beginPath()
        for (var i = 0; i < 6; i++) {
            this.context.lineTo(this.x + (this.size - this.padding) * Math.cos(this.angle * i), this.y + (this.size - this.padding) * Math.sin(this.angle * i))
        }
        this.context.closePath()
        this.context.fill()

        // Boarder
        this.context.strokeStyle = this.boarderColour
        this.context.lineWidth = 1
        this.context.beginPath()
        for (var i = 0; i < 6; i++) {
            this.context.lineTo(this.x + (this.size - this.margin) * Math.cos(this.angle * i), this.y + (this.size - this.margin) * Math.sin(this.angle * i))
        }
        this.context.closePath()
        this.context.stroke()

        // Label
        this.context.font = "10px monospace";
        this.context.fillStyle = 'blue'
        var textmetrics = this.context.measureText(this.label)
        console.log(textmetrics)
        this.context.fillText(this.label, this.x - (textmetrics.width/2), this.y + (textmetrics.emHeightAscent/2));
    }
}

class HexTerrainController extends Controller {

    static targets = ["canvas"]

    connect() {
        console.log("starchart controller connected")

        this.canvas = this.canvasTarget
        this.context = this.canvas.getContext("2d")

        this.canvas.width  = window.innerWidth
        this.canvas.height = window.innerHeight

        this.context.fillStyle = "black"
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)

        setTimeout(() => {
            this.draw()
        })
        
    }

    draw() {
        var grid = new Array()
        var offset = 80
        var cellSize = 30
        var angle = 2 * Math.PI / 6;

        for (var x = 0; x < 20; x++) {
            grid.push(new Array())
            for (var y = 0; y < 10; y++) {
                let cellX = (x * cellSize) * (1 + Math.cos(angle))
                let cellY = ((2*y+(x % 2 == 0 ? 0 : 1)) * cellSize) * Math.sin(angle)

                let cell = new Cell(this.context, offset + cellX, offset + cellY, cellSize, `rgba(255, 255, 255, ${randomFloat(0.3, 0.7)})`, `rgba(255, 255, 255, 0.8)`)
                cell.label = `${x}, ${y}`
                grid[x].push(cell)
            }
        }

        grid.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
                setTimeout(() => {
                    cell.draw()
                }, 20 * (rowIndex + cellIndex))
            })
        });
    }
}

export default HexTerrainController