import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["body"]

    // connect() {
    //     // this.renderChart();
    // }

    toggle(event) {
        event.preventDefault();
        this.bodyTargets.forEach((body) => {
            body.classList.toggle("hidden")
        })
    }
}
