import { Controller } from "@hotwired/stimulus"
import { randomInt, randomFloat } from "./helpers.js"

class Star {
    constructor(context, x, y) {
        this.context = context
        this.x = x
        this.y = y
        this.size = randomInt(0, 5)
    }

    draw() {
        this.context.fillStyle = "white"
        this.context.fillRect(this.x - (this.size / 2), this.y - (this.size / 2), this.size, this.size)
    }
}

class Grid {
    constructor(context, canvas) {
        this.context = context
        this.crosshairColour = "rgba(255, 255, 255, 0.2)"
        this.crosshairSize = 8
        this.crosshairwidth = 1
        
        this.gridLineColour = "rgba(255, 255, 255, 0.05)"
        this.gridLineWidth = 1

        this.gridSpacing = 120
        this.gridLinePadding = 0

        this.setup()
    }

    setup() {
        let firstPoint = ((this.context.canvas.width / 2.0) % this.gridSpacing) - this.gridSpacing

        this.points = new Array();
  
        var x = firstPoint;
        var y = 0;

        while((y - this.gridSpacing) < this.context.canvas.height) {
            while((x - this.gridSpacing) < this.context.canvas.width) {
                this.points.push({"x": x, "y": y});
                x = x + this.gridSpacing;
            }
            x = firstPoint;
            y = y + this.gridSpacing;
        }
    }

    draw() {
        this.drawGridSquares()
        this.drawGridLines()
        this.drawCrosshairs()
    }

    drawGridSquares() {
        this.points.forEach((point) => {
            this.context.fillStyle = `rgba(${randomInt(0, 255)}, ${randomInt(0, 255)}, ${randomInt(255)}, ${randomFloat(0, 1)})`
            this.context.fillRect(point.x, point.y, this.gridSpacing, this.gridSpacing)
        })
    }

    drawGridLines() {
        this.points.forEach((point) => {
            this.context.save()

            this.context.beginPath()

            this.context.translate(point.x, point.y)

            this.context.lineWidth = this.gridLineWidth
            this.context.strokeStyle = this.gridLineColour

            this.context.moveTo(this.gridLinePadding, this.gridLinePadding)
            this.context.lineTo(this.gridSpacing - this.gridLinePadding, this.gridLinePadding)
            this.context.lineTo(this.gridSpacing - this.gridLinePadding, this.gridSpacing - this.gridLinePadding)
            this.context.lineTo(this.gridLinePadding, this.gridSpacing - this.gridLinePadding)
            this.context.lineTo(this.gridLinePadding, this.gridLinePadding)

            this.context.closePath()
            this.context.stroke()

            this.context.restore()
        })
    }

    drawCrosshairs() {
        this.points.forEach((point) => {
            this.context.save()
            this.context.translate(point.x, point.y)
            
            this.context.beginPath()
            this.context.strokeStyle = this.crosshairColour
            this.context.lineWidth = this.crosshairwidth
    
            this.context.moveTo(0, this.crosshairSize)
            this.context.lineTo(0, 0 - this.crosshairSize)
    
            this.context.moveTo(this.crosshairSize, 0)
            this.context.lineTo(0 - this.crosshairSize, 0)
    
            this.context.closePath()
            this.context.stroke()
            
            this.context.restore()
        })
    }

}

export default class extends Controller {

    static targets = [ "canvas" ]

    connect() {
        console.log("starchart controller connected")

        this.canvas = this.canvasTarget
        this.context = this.canvas.getContext("2d")

        this.canvas.width  = window.innerWidth
        this.canvas.height = window.innerHeight

        this.context.fillStyle = "black"
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)


        this.grid = new Grid(this.context)

        this.stars = []
        for (var i = 0; i < 100; i++) {
            this.stars.push(new Star(this.context, randomInt(0, this.canvas.width), randomInt(0, this.canvas.height)))
        }

        this.draw()
    }

    draw() {
        this.grid.draw()

        this.stars.forEach((star) => {
            star.draw()
        })
    }
}






// // This is a manifest file that'll be compiled into including all the files listed below.
// Add new JavaScript/Coffee code in separate files in this directory and they'll automatically
// be included in the compiled file accessible from http://example.com/assets/application.js
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// the compiled file.
//
//= require jquery
//= require jquery_ujs
//= require bootstrap
//= require magnific-popup
//= require picfair.js
//= require jquery_nested_form
//= require Chart.min
//= require_tree .

// $( document ).ready(function() {
//     $("#astrorunner .screenshots a").magnificPopup({type:'image'});
  
//     createBackgroundCanvas();
//     refreshBackgroundCanvas();
  
//     $(".image-details").click(function(event) {
//       var elementToHide = $(event.target);
//       var elementToShow = $(event.target).siblings('.image-details-invisible');
  
//       elementToHide.addClass('image-details-invisible');
//       elementToHide.removeClass('image-details-visible');
  
//       elementToShow.addClass('image-details-visible');
//       elementToShow.removeClass('image-details-invisible');
//     });
  
//   });
  
//   $(window).resize(function() {
//     refreshBackgroundCanvas();
//   });
  
//   function createBackgroundCanvas() {
//     var canvas = document.createElement('canvas');
//     canvas.setAttribute("id", "background-canvas");
//     canvas.width = $("body").width(); //window.innerWidth;
//     canvas.height = $(window).height();
//     canvas.style.position = "fixed";
//     canvas.style.top = "0";
//     canvas.style.left = "0";
//     canvas.style.zIndex = "-1";
//     document.body.appendChild(canvas);
//   }
  
//   function refreshBackgroundCanvas() {
//     var canvas = document.getElementById("background-canvas");
//     var c = canvas.getContext('2d');
  
//     canvas.width = $("body").width(); //window.innerWidth;
//     canvas.height = $(window).height();
  
//     drawBackground(canvas, c);
//     drawGrid(canvas, c);
//   }
  
//   var gridColour = '#333';
//   var gridSpacing = 120;
  
//   var gridBackgroundColour;
//   if ($('body').hasClass('admin')) {
//     gridBackgroundColour = '#333';
//   } else {
//     gridBackgroundColour = '#f5f5f5';
//   }
  
//   function drawGrid(canvas, c) {
  
//     var firstPoint = ((canvas.width/2.0 ) % gridSpacing) - gridSpacing;
//     //console.log("canvas width: " + canvas.width);
//     //console.log("first x: " + firstPoint);
  
//     var points = new Array();
  
//     var x = firstPoint;
//     var y = 0;
  
//     while((y - gridSpacing) < canvas.height) {
//       while((x - gridSpacing) < canvas.width) {
//         c.save();
  
//         c.translate(x, y);
//         drawCrosshairs(c)
//         points.push({"x": x, "y": y});
  
//         x = x + gridSpacing;
  
//         c.restore();
//       }
//       x = firstPoint;
//       y = y + gridSpacing;
//     }
  
//     console.log(points)
  
//     for(i=0; i < 120; i++) {
//       c.beginPath();
//       c.strokeStyle = "#999";
//       c.lineWidth = 0.1;
  
//       //size = 8;
  
//       var pointA = points[randomInt(1, points.length)]
//       var pointB = points[randomInt(1, points.length)]
//       var pointC = points[randomInt(1, points.length)]
  
//       c.moveTo(pointA.x, pointA.y);
//       c.lineTo(pointB.x, pointB.y);
//       c.lineTo(pointC.x, pointC.y);
  
//       c.closePath();
//       c.stroke();
//     }
  
//   }
  
//   function drawBackground(canvas, c){
//     c.fillStyle = gridBackgroundColour;
//     c.fillRect(0,0,canvas.width, canvas.height);
//   }
  
//   function randomFloat(min, max) {
//     return Math.random() * (max-min) + min;
//   }
//   function randomInt(min, max) {
//     return parseInt(randomFloat(min, max))
//   }
  
//   function drawCrosshairs(c){
//     c.beginPath();
//     c.strokeStyle = gridColour;
//     c.lineWidth = 0.1;
  
//     size = 8;
  
//     c.moveTo(0, size);
//     c.lineTo(0, 0 - size);
  
//     c.moveTo(size, 0.5);
//     c.lineTo(0 - size, 0.5);
  
//     c.closePath();
//     c.stroke();
//   }