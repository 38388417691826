import { Controller } from "@hotwired/stimulus"
// import { randomInt } from "./helpers"

class WolfenDoomRayCasterController extends Controller {

    // Stimulus targets
    static targets = ["canvas", "framerate"]

    // Canvas variables
    // width = 480
    // height = 320
    width = 320
    height = 240
    // width = 18
    // height = 12
    pixelCount = this.width * this.height
    pixelArrayLength = this.pixelCount * 4

    // Framerate variables
    startTimestamp = document.timeline.currentTime
    previousTimestamp = 0
    differenceTime = 0

    targetFramerate = 35
    targetFramerateTime = 1000 / this.targetFramerate

    frameratesWindow = 60
    framerates = new Array(this.frameratesWindow + 1).fill(this.targetFramerate)

    imageData = new Uint8ClampedArray()

    fov = 60
    hfov = this.fov / 2

    // Animation variables
    nextAnimationFrame = undefined

    showMap = false

    keyboardState = {
        w: false,
        s: false,
        a: false,
        d: false,
        Shift: false,
        // Tab: false
    }

    mapSectors = [
        // ID, floorHeight, ceilingHeight, lightLevel, special, tag
        {
            // id: 0,
            floorHeight: 0,
            ceilingHeight: 10,
            lightLevel: 255,
            lineDefs: [
                { x1: 140, y1: 80, x2: 180, y2: 80, colour: {r: 210, g: 220, b: 200, a: 255} },
                { x1: 180, y1: 80, x2: 200, y2: 100, colour: {r: 200, g: 210, b: 220, a: 255} },
                { x1: 200, y1: 100, x2: 200, y2: 140, colour: {r: 200, g: 200, b: 210, a: 255} },
                { x1: 200, y1: 140, x2: 180, y2: 160, colour: {r: 210, g: 220, b: 200, a: 255} },
                { x1: 180, y1: 160, x2: 140, y2: 160, colour: {r: 200, g: 210, b: 220, a: 255} },
                { x1: 140, y1: 160, x2: 120, y2: 140, colour: {r: 200, g: 200, b: 210, a: 255} },
                { x1: 120, y1: 140, x2: 120, y2: 100, colour: {r: 210, g: 220, b: 200, a: 255} },
                { x1: 120, y1: 100, x2: 140, y2: 80, colour: {r: 200, g: 210, b: 220, a: 255} },

                // green triangle
                { x1: 140, y1: 100, x2: 160, y2: 100, colour: {r: 0, g: 110, b: 0, a: 255} },
                { x1: 160, y1: 100, x2: 140, y2: 120, colour: {r: 0, g: 120, b: 0, a: 255} },
                { x1: 140, y1: 120, x2: 140, y2: 100, colour: {r: 0, g: 130, b: 0, a: 255} },

                // blue triangle
                { x1: 180, y1: 140, x2: 160, y2: 140, colour: {r: 0, g: 0, b: 110, a: 255} },
                { x1: 160, y1: 140, x2: 180, y2: 120, colour: {r: 0, g: 0, b: 120, a: 255} },
                { x1: 180, y1: 120, x2: 180, y2: 140, colour: {r: 0, g: 0, b: 130, a: 255} },
            ]
        }
    ]

    // Canvas Elements

    player = {
        x: this.width / 2,
        y: this.height / 2,
        speed: 0.0,
        straffeSpeed: 0.0,
        angle: 0, // in degrees
        boundingBox: 3
    }

    connect() {
        console.log("Hello from canvasbuffers_controller.js")

        this.framerateElement = this.framerateTarget

        this.canvas = this.canvasTarget
        this.context = this.canvas.getContext("2d")

        this.canvas.width = this.width
        this.canvas.height = this.height

        this.canvas.style.width = "50%"
        this.canvas.style.imageRendering = "pixelated"

        document.addEventListener('keydown', this.keyboardHandler.bind(this))
        document.addEventListener('keyup', this.keyboardHandler.bind(this))

        this.canvas.addEventListener("click", async () => {
            await this.canvas.requestPointerLock({
                unadjustedMovement: true,
            });
        });

        window.requestAnimationFrame(this.drawFrame.bind(this))
    }

    keyboardHandler(event) {
        this.keyboardState[event.key] = event.type == 'keydown';

        if (event.key == "Tab" && event.type == 'keydown') {
            this.showMap = !this.showMap
        }
    }

    degreesToRadians(degrees) {
        return degrees * (Math.PI / 180)
    }

    drawFrame(timestamp) {
        // Request the next frame
        this.nextAnimationFrame = window.requestAnimationFrame(this.drawFrame.bind(this))
        this.differenceTime = (timestamp - this.previousTimestamp)

        // only draw if we have passed enough time to meet our target framerate
        if (this.differenceTime < this.targetFramerateTime) {
            // return because we are too fast
            return
        }

        this.framerates = this.framerates.slice(1) // Copy the array, removing the first element
        this.framerates.push(parseInt(1000 / (timestamp - this.previousTimestamp))) // Add the current frameTime
        this.framerateElement.innerText = parseInt(this.framerates.reduce((a, b) => a + b, 0) / this.frameratesWindow) // Update the framerate element
        this.previousTimestamp = performance.now() // Update the previous timestamp for framerate calculation

        let sectorsToDraw = []

        // Update player position
        // if (this.keyboardState.Shift) {
        //     this.player.speed = 1
        // } else {
        //     this.player.speed = 0.2
        // }
        // if (this.keyboardState.w || this.keyboardState.s) {
        if (this.keyboardState.w) {
            this.player.speed += 0.2
            if (this.player.speed > 4) {
                this.player.speed = 4
            }
        } else if (this.keyboardState.s) {
            this.player.speed -= 0.2
            if (this.player.speed < -2) {
                this.player.speed = -2
            }
        } else {
            if (this.player.speed > 0) {
                this.player.speed -= 0.5
                if (this.player.speed < 0) {
                    this.player.speed = 0
                }

            } else if (this.player.speed < 0) {
                this.player.speed += 0.5
                if (this.player.speed > 0) {
                    this.player.speed = 0
                }
            }
        }

        if (this.keyboardState.a) {
            this.player.angle -= 15
        }
        if (this.keyboardState.d) {
            this.player.angle += 15
        }

        // Move Player
        if (this.player.speed != 0) {
            this.player.x = this.player.x + Math.cos(this.degreesToRadians(this.player.angle)) * this.player.speed
            this.player.y = this.player.y + Math.sin(this.degreesToRadians(this.player.angle)) * this.player.speed
        }

        this.context.fillStyle = "#333333"
        this.context.fillRect(0, 0, this.width, this.height)

        // get image data
        this.imageData = this.context.getImageData(0, 0, this.width, this.height)

        sectorsToDraw.push({
            id: 0,
            rays: []
        })

        let vindex = 0
        // draw fov rays for each vertical line
        for (let rayAngle = this.player.angle - this.hfov; rayAngle < this.player.angle + this.hfov; rayAngle += (this.fov / this.width)) {

            let ray = {
                angle: rayAngle,
                index: vindex++,
                x1: this.player.x,
                y1: this.player.y,
                x2: this.player.x + Math.cos(this.degreesToRadians(rayAngle)) * 1000,
                y2: this.player.y + Math.sin(this.degreesToRadians(rayAngle)) * 1000,
            }

            this.mapSectors[0].lineDefs.forEach(lineDef => {
                let intersection = this.intersectLines(ray.x1, ray.y1, ray.x2, ray.y2, lineDef.x1, lineDef.y1, lineDef.x2, lineDef.y2)
                if (!isNaN(intersection.x)) {
                    ray.x2 = intersection.x
                    ray.y2 = intersection.y
                    ray.lineDef = lineDef
                }
            })

            ray.distance = Math.sqrt(Math.pow(ray.x2 - ray.x1, 2) + Math.pow(ray.y2 - ray.y1, 2))
            ray.euclideanDistance = (Math.cos(this.degreesToRadians(this.player.angle - ray.angle)) * ray.distance)
            
            let wallHeight = (this.height * 10) / (ray.euclideanDistance)
            wallHeight = wallHeight > this.height ? this.height : wallHeight
            wallHeight = Math.floor(wallHeight)

            let wallTop = ((this.height / 2) - (wallHeight / 2))
            wallTop = wallTop < 0 ? 0 : wallTop
            wallTop = Math.floor(wallTop)

            this.drawVerticalLine(ray.index, wallTop, wallTop + wallHeight, ray.lineDef.colour.r, ray.lineDef.colour.g, ray.lineDef.colour.b, ray.lineDef.colour.a)

            sectorsToDraw[0].rays.push(ray)
        }

        ////////////////////////////////
        // Commit Image Data
        ////////////////////////////////

        this.context.putImageData(this.imageData, 0, 0)

        ////////////////////////////////
        // Draw Map
        ////////////////////////////////
        if (this.showMap) {
            // draw player
            this.context.fillStyle = "#ff0000"
            this.context.fillRect(this.player.x - (this.player.boundingBox / 2), this.player.y - (this.player.boundingBox / 2), this.player.boundingBox, this.player.boundingBox)

            // draw player pole
            this.context.fillStyle = "#ff0000"
            this.context.fillRect(
                Math.floor(this.player.x + Math.cos(this.degreesToRadians(this.player.angle)) * 5),
                Math.floor(this.player.y + Math.sin(this.degreesToRadians(this.player.angle)) * 5)
                , 1, 1)


            // Draw Sector
            this.context.strokeStyle = "#ffffff"
            this.context.lineWidth = 0.5
            for (let i = 0; i < this.mapSectors.length; i++) {
                for (let j = 0; j < this.mapSectors[i].lineDefs.length; j++) {
                    this.context.beginPath()
                    this.context.moveTo(this.mapSectors[i].lineDefs[j].x1, this.mapSectors[i].lineDefs[j].y1)
                    this.context.lineTo(this.mapSectors[i].lineDefs[j].x2, this.mapSectors[i].lineDefs[j].y2)
                    this.context.stroke()
                }
            }

            sectorsToDraw.forEach(sector => {
                sector.rays.forEach(ray => {
                    // draw the sector ray
                    this.context.strokeStyle = "#ff0000"
                    this.context.lineWidth = 0.5
                    this.context.beginPath()
                    this.context.moveTo(ray.x1, ray.y1)
                    this.context.lineTo(ray.x2, ray.y2)
                    this.context.stroke()
                })
            })
        }

    }

    // js-ified from https://github.com/Immugio/three-math-extensions/blob/master/src/Line2D.ts
    // Line 1: x1, y1 -> x2, y2
    // Line 2: x3, y3 -> x4, y4
    intersectLines(x1, y1, x2, y2, x3, y3, x4, y4) {

            // Check if none of the lines are of length 0
            if((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
            return { x: NaN, y: NaN }
        }

        let denominator = ((y4 - y3) * (x2 - x1)) - ((x4 - x3) * (y2 - y1))
        // console.log(denominator)

        // Lines are parallel
        if (denominator === 0) {
            return { x: NaN, y: NaN }
        }

        // const ua = ((other.end.x - other.start.x) * (this.start.y - other.start.y) - (other.end.y - other.start.y) * (this.start.x - other.start.x)) / denominator;
        let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator

        // Check if the intersection point is within the bounds of the line segments if required
        if (true) { //(lineSegmentOnly) {
            let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
            if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
                return { x: NaN, y: NaN }
            }
        }

        // Return an object with the x and y coordinates of the intersection
        let x = x1 + ua * (x2 - x1)
        let y = y1 + ua * (y2 - y1)

        return { x: x, y: y }
    }


    // returns the array index of the pixel at x, y
    translateXYToIndex(x, y) {
        return Math.floor(((this.width * 4) * y) + (x * 4))
    }

    drawVerticalLine(x, y0, y1, r, g, b, a) {
        for (let y = y0; y < y1; y++) {
            let pos = this.translateXYToIndex(x, y)
            this.imageData.data[pos] = r
            this.imageData.data[pos + 1] = g
            this.imageData.data[pos + 2] = b
            this.imageData.data[pos + 3] = a
        }
    }

    drawRect(x, y, width, height, r, g, b, a, imageData) {
        for (let w = 0; w < width; w++) {
            for (let h = 0; h < height; h++) {
                // find the position of x,y in the imageData array
                let pos = this.translateXYToIndex(x + w, y + h)
                imageData.data[pos] = r
                imageData.data[pos + 1] = g
                imageData.data[pos + 2] = b
                imageData.data[pos + 3] = a
            }
        }
    }

}

export default WolfenDoomRayCasterController