import { Controller } from "@hotwired/stimulus"
// import { elementSelectorParser } from "tailwindcss/lib/lib/resolveDefaultsAtRules";

export default class extends Controller {

    static targets = [ "canvas" ]

    connect() {
        console.log("Pomodoro controller connected")
    }

    async start() {
        //var currentIndex = 0
        var pomodoroIndex = 1500 // 1500, 50
        var endIndex = 1800 // 1800, 60

        //await this.renderInitial()

        let canvas = this.canvasTarget
        let context = canvas.getContext("2d")

        canvas.width  = window.innerWidth;
        canvas.height = window.innerHeight;

        let progressWidth = this.mapValueInRangeToRange(1, endIndex, canvas.width) 

        context.beginPath();
        context.rect(0, 0, canvas.width, canvas.height)
        context.fillStyle = "rgb(20, 20, 20)";
        context.fill();

        for (let index = 0; index < endIndex; index++) { 

            // this breaks the render loop :shrug:
        //canvas.width  = window.innerWidth;
        //canvas.height = window.innerHeight;

        // draw transparent layer
        //context.fillStyle = "rgba(20,20,20,0.2)"
        //context.fillRect(0, 0, canvas.width, canvas.height)



        
        context.beginPath();
        context.rect(0, 0, canvas.width, canvas.height)
        context.fillStyle = "rgba(20, 20, 20, 0.1)";
        context.fill();
        

        // draw progress
        context.beginPath();
        context.rect(this.mapValueInRangeToRange(index, endIndex, canvas.width), 0, progressWidth, canvas.height)
        context.fillStyle = "rgb(50, 50, 50)"
        context.fill();
        // context.fillRect(this.mapValueInRangeToRange(index, endIndex, canvas.width), 0, progressWidth, canvas.height)



            console.log(index)
            // this.render(index, pomodoroIndex, endIndex)
            await this.sleep(10)
        }
    }

    canvasClickHandler(event) {
        console.log("canvas clicked")
    }

    // async renderInitial() {
    //     var canvas = this.canvasTarget
    //     canvas.width = window.innerWidth
    //     canvas.height = window.innerHeight

    //     var context = canvas.getContext("2d")
    //     context.fillStyle = "rgb(20,20,20)"
    //     context.fillRect(0, 0, canvas.width, canvas.height)
    // }

    // render(index, pomodoroIndex, endIndex) {
    //     var canvas = this.canvasTarget
    //     canvas.width  = window.innerWidth;
    //     canvas.height = window.innerHeight;

    //     var context = canvas.getContext("2d")

    //     // draw transparent layer
    //     context.fillStyle = "rgba(20,20,20,0.2)"
    //     //context.fillRect(0, 0, canvas.width, canvas.height)

    //     // draw progress
    //     context.fillStyle = "rgb(50,50,50)"
    //     context.fillRect(this.mapValueInRange(index, endIndex, canvas.width), 0, this.mapValueInRange(index, endIndex, canvas.width) + this.mapValueInRange(1, endIndex, canvas.width), canvas.height)

    //     // draw pomodoro line


    // }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    mapValueInRangeToRange(value, fromEnd, toEnd) {
        // return value * (toEnd / fromEnd)
        let percent = value / fromEnd
        let toValue = toEnd * percent
        return toValue
    }

}
