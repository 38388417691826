import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";
import "moment";

export default class extends Controller {

    static targets = ["canvas"]

    connect() {
        this.renderChart();
    }

    renderChart() {
        const ctx = this.canvasTarget.getContext("2d");

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: JSON.parse(this.canvasTarget.dataset["dataset-labels"]),
                datasets: [{
                    label: 'Cameras',
                    data: JSON.parse(this.canvasTarget.dataset["dataset-data"]),
                    // data: {
                    //     "January": 12,
                    //     "February": 23,
                    //     "March": 14,
                    //     "April": 16,
                    //     "May": 18,
                    //     "June": 14,
                    //     "July": 11,
                    //     "August": 16,
                    //     "September": 20,
                    //     "October": 18,
                    //     "November": 12,
                    //     "December": 12
                    // }
                    // data: [
                    //     [65, 76], 
                    //     [59, 62], 
                    //     [80, 83], 
                    //     [81, 89], 
                    //     [56, 60], 
                    //     [55, 80], 
                    //     [40, 68]
                    // ],
                    // data: {
                    //     "January": [65, 76], 
                    //     "February": [59, 62], 
                    //     "March": [80, 83], 
                    //     "April": [81, 89], 
                    //     "May": [56, 60], 
                    //     "June": [55, 80], 
                    //     "July": [40, 68]
                    // },
                    // indexAxis: 'y',
                    // backgroundColor: [
                    // 'rgba(255, 99, 132, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)',
                    // 'rgba(255, 205, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(54, 162, 235, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(201, 203, 207, 0.2)'
                    // ],
                }]
            },
            options: {
                responsive: true,
                indexAxis: 'y',
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'year'
                        },
                        // bounds: 'data',
                        min: this.canvasTarget.dataset["dataset-min"],
                        max: this.canvasTarget.dataset["dataset-max"],
                        //beginAtZero: false
                    },
                    y: {
                        // beginAtZero: false
                        ticks: {
                            font: {
                                size: 8
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                //     tooltip: {
                //         enabled: false
                //     }
                }
            }
        });
    }
}
