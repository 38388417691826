import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

export default class extends Controller {

    static targets = ["chart"]

    connect() {
        let width = this.chartTarget.dataset.width || 480
        let height = this.chartTarget.dataset.height || 240
        let showLabels = (this.chartTarget.dataset.showLabels === "false" ? false : true)

        let primaryColour = this.chartTarget.dataset.primaryColour || "#336699"
        let secondaryColour = this.chartTarget.dataset.secondaryColour || "#225588"
        let highlightColour = this.chartTarget.dataset.highlightColour || "#4477aa"

        console.log("width", width)
        console.log("height", height)
        console.log("showLabels", showLabels)
        console.log("data", this.chartTarget.dataset.showLabels )

        var data = JSON.parse(this.chartTarget.dataset.data)
        data = data.map((d) => {
            return {
                date: new Date(d.date),
                value: d.value,
            }
        })
        console.log("data", data)

        // Setup the svg element
        var svg = d3.select(this.chartTarget)
            .attr("width", width)
            .attr("height", height)

        // Setup the X axis scales
        var x = d3.scaleBand()
            .domain(data.map(d => d.date))
            .range([0, width])
            .padding(0.01)
        
        // Setup the Y axis scales

        var y = d3.scaleLinear()
            .domain([0, d3.max(data, (d) => d.value)])
            .range([height, 0])

        // Draw the bars
        svg.selectAll("bars")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d) => x(d.date))
            .attr("y", (d) => y(d.value))
            .attr("width", x.bandwidth())
            .attr("height", (d) => height - y(d.value))
            .attr("fill", (d) => { 
                return (d.date.getDay() == 6 || d.date.getDay() == 0 ? secondaryColour : primaryColour)
            })
            .on("mouseover", function() {
                d3.select(this).attr("fill", highlightColour)
            })
            .on("mouseout", function() {
                d3.select(this).attr("fill", (d) => { 
                    return (d.date.getDay() == 6 || d.date.getDay() == 0 ? secondaryColour : primaryColour)
                })
            })

        // Draw the X axis
        let xAxis = svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", `translate(0,${height - 30})`)
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%a")))

        if (showLabels) {
        xAxis.selectAll("text")
            .style("fill", "#fff")
            .style("text-anchor", "middle")
        } else { 
        xAxis.selectAll("text")
            .remove()
        }
        
        // Draw the Y axis
        let yAxis = svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
        
        if (showLabels) {
        yAxis.selectAll("text")
                .attr("x", "10")
                .style("text-anchor", "start")
                .style("fill", "#000")
        } else {
        yAxis.selectAll("text")
                .remove()
        }
    }

}
